import {setCartQtyIcon} from "./helpers";

$(window).on('load', onLoad)
// hamburger menu
$('#hamburger-menu input').on('change', toggleResponsiveNav)

function toggleResponsiveNav() {
    $('body').toggleClass('open-nav')
}

function onLoad() {
    setCartQtyIcon()
}
